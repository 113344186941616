import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { Routing } from 'src/app/core/enums/routing.enum';
import { LANDINGS_URL, SEO, SEO_FLIGHTS, SEO_HOTELS } from 'src/environments/environment';

export const errorHandlerService: HttpInterceptorFn = (req, next) => {
  const paths = [SEO, SEO_FLIGHTS, SEO_HOTELS, LANDINGS_URL.landings];
  const router = inject(Router);
  return next(req).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 404 && paths.some(path => req.url.includes(path))) {
          router.navigate([Routing.NOT_FOUND]);
        }
      }
      return throwError(() => err);
    })
  );;
};