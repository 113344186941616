import { ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
/** date setting */
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

import { routes } from './app.routes';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withJsonpSupport } from '@angular/common/http';
import { ALLIANCES_API_URL, AlliancesService as AlliancesServiceCodex, APP_NAME, GlobalErrorHandler, GoogleSignInModule, WEB_MONITOR_URL } from '@cocha/ngx-codex';
import { ALLIANCES_CONFIG, GOOGLE_PROVIDER_ID, webMonitorUrl } from '../environments/environment';
import { transferStateInterceptor } from './core/interceptors/transfer-state.interceptor';
import { AlliancesService as AllianServiceLocal } from './shared/services/alliances.service';
import { errorHandlerService } from './shared/services/error-handler.service';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      GoogleSignInModule.initialize({ apiKey: GOOGLE_PROVIDER_ID, autoLogin: false, enableLocalStorageToken: true }),
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        transferStateInterceptor,
        errorHandlerService,
      ]),
      withJsonpSupport()
    ),
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: ALLIANCES_API_URL, useValue: ALLIANCES_CONFIG },
    { provide: WEB_MONITOR_URL, useValue: webMonitorUrl },
    { provide: APP_NAME, useValue: 'home' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: AlliancesServiceCodex, useExisting: AllianServiceLocal },
  ]
};