import { Component, DestroyRef, Inject } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { FooterComponent, PreHeaderComponent, Source } from '@cocha/ngx-codex';
import { LandingSubscribeComponent } from './pages/landing/components/landing-subscribe/landing-subscribe.component';
import { LandingTipsComponent } from './pages/landing/components/landing-tips/landing-tips.component';
import { LandingPaymentMethodComponent } from './pages/landing/components/landing-payment-method/landing-payment-method.component';
import { LandingQuestionsComponent } from './pages/landing/components/landing-questions/landing-questions.component';
import { LandingMostSeenLinksComponent } from './pages/landing/components/landing-most-seen-links/landing-most-seen-links.component';
import { LandingSealsComponent } from './pages/landing/components/landing-seals/landing-seals.component';
import { DOCUMENT } from '@angular/common';
import { LandingSetupService } from './shared/services/landing-setup.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlliancesService } from './shared/services/alliances.service';
import { filter, first } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    PreHeaderComponent,
    LandingSubscribeComponent,
    LandingTipsComponent,
    LandingPaymentMethodComponent,
    LandingQuestionsComponent,
    LandingMostSeenLinksComponent,
    LandingSealsComponent,
    FooterComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  currentTheme?: string;
  constructor(
    private alliancesService: AlliancesService,
    @Inject(DOCUMENT) private document: Document,
    private landingSetupService: LandingSetupService,
    private destroyRef: DestroyRef,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      first(),
    ).subscribe((event: NavigationEnd) => this.setupSource(event.urlAfterRedirects));
  }

  private setupSource(url: string) {
    this.landingSetupService.getSourceActive(url)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((source: any) => {
        if (source && source !== Source.COCHA) {
          this.addClassToBody(`${source}-theme`);
        } else {
          source = Source.COCHA;
        }
        this.alliancesService.setSource(source);
      });
  }

  private addClassToBody(themeName: string) {
    if (this.currentTheme) {
      this.document.body.classList.replace(this.currentTheme, themeName);
    } else {
      this.currentTheme = themeName;
      this.document.body.classList.add(this.currentTheme);
    }
  }
}